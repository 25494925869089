import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Banque à distance VS Banque en ligne, la bataille avec BankBank</title>
                <meta name="description" content="
              Avant la banque en ligne il y'avait la banque à distance des banques traditionnelles. Qu'en est-t-il aujourdhui ? Y'a t-il vraiment une différence ?" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Banque à distance</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Banque à distance</Title>
			<StaticImage
		  	src='../../images/banque-a-distance.jpeg'
			width={1200}
			alt='Banque à distance'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		<p>	Les banques à distance sont de plus en plus présentes dans le paysage bancaire. Les services proposés par les banques à distance sont des solutions pour suivre et gérer ses comptes bancaires sans se déplacer. L’accès est disponible depuis son ordinateur, son mobile, ou encore sa tablette. En offrant un service de banque en ligne, les banques espèrent  se rapprochent des besoins de leurs clients, dont l’usage du digital est de plus en plus fréquent.
		</p><p>
Quels services proposent-elles ? A qui s’adresse-t-elles ? Et comment souscrire à une banque à distance ? 
</p><p>
Ne pas confondre banque à distance et banque en ligne. Les banques à distance sont des services supplémentaires proposés par les banques de réseau.Alors qu’une banque en ligne propose 100 % de ses services à distance, et ne dispose donc pas de guichet.
</p>
<h2>Les services de la banque à distance</h2>
<p>La plupart des banques de réseau  proposent plusieurs services à distance, plus besoin de se déplacer pour gérer et consulter ses comptes
</p><p>
Les opérations bancaires qu’il est possible de réaliser à tout moment  sont :
</p>
<ul>
	<li>la consultation de comptes (solde et historique)</li>
	<li>le détail des mouvements</li>
	<li>la consultation du portefeuille de titres et de sa valorisation</li>
	<li>l’impression de RIB</li>
	<li>la  possibilité d’effectuer des virements (virement interne et externe)</li>
	<li>la possibilité de commander des chéquiers</li>
	<li>la possibilité d’être mise en relation avec son conseiller</li>
	<li>la souscription à une nouvelle carte bancaire</li>
	<li>l’opposition à sa carte bancaire</li>
	<li>Il est également possible dans certains cas de consulter son épargne, et de gérer ses placements.</li>
</ul>


<p>
La banque à distance permet également de gérer ses produits financiers, et notamment de gérer les contrats d’assurance (par exemple pour l’assurance habitation il est possible de faire sa déclaration de sinistres directement sur sa banque en ligne).
</p><p>
Acheter ou vendre des titres en Bourse est également possible depuis sa banque à distance, avec la plupart du temps des frais réduits.
</p>
<h2>Ouvrir un compte banque à distance</h2>
<p>La plupart du temps, les banques de réseau disposent d’un portail sur leurs sites permettant de se diriger vers les sites des banques à distance. La connexion nécessite un identifiant et un code d’accès. Généralement, est mis en place un service technique accessible par téléphone, en cas de besoin, ou pour répondre à toutes questions.
</p><p>
Exemple de la banque à distance Caisse d’Epargne.
</p>
<h2>Pourquoi souscrire à une banque à distance ?</h2>
<p>Pour plusieurs raisons, il est intéressant de se souscrire à une banque en ligne.
</p><p>
La plus évidente est la possibilité de gérer son compte à distance depuis chez soi. Que ce soit pour un simple virement ou pour connaître le solde de son compte courant, plus besoin de se rendre à son agence.
</p><p>
De plus, comme pour les banques en ligne, il n’y a pas de restriction d’horaire, il est possible d’accéder à son espace personnel à n’importe quel moment. Les banques en ligne n’ont pas pour vocation de remplacer un conseiller bancaire, mais permettent de faciliter la gestion quotidienne des clients.
</p>
<h2>La banque à distance, c’est pour qui ?</h2>
<p>Comme les banques en ligne, les banques à distance visent essentiellement une clientèle de plus en plus mobile, avec des profils très divers.
</p><p>
Certaines banques ont créé des sites destinés à une clientèle plus jeune, on peut citer  par exemple «Mozaic» du Crédit Agricole, ou encore « Badoo » de la Banque Postale.
</p>
<h2>Comment souscrire à une banque à distance ?</h2>
<p>Les abonnements au service de la banque à distance doivent figurer dans les tarifs standards de la banque. La majorité des banques proposent ces services gratuitement.
</p><p>
Les services de banques en ligne sont généralement proposés à la souscription du compte, compris dans le « package » de départ. A défaut, il convient de souscrire au service de la banque en ligne auprès de votre banque. Les prix variés selon les banques, et selon les fonctionnalités offertes.
</p>
<h2>La sécurité de la banque à distance</h2>
<p>Les banques à distance investissent régulièrement dans des systèmes de sécurité. Elles informent leurs clients à partir d’une rubrique spécifique sur le site internet de la banque, et  elles publient régulièrement des alertes et mises en garde. De plus, certaines opérations, comme les virements bancaires, sont soumises à une sécurité renforcée.
</p>		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
